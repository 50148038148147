/* body {
  background-color: #f5efef;
  font-family: sans-serif;
} */

/* body {
  background-color: #f5f0f0;
  font-family: sans-serif;
} */

body {
  background-color: #f6f1f1;
  font-family: sans-serif;
}
